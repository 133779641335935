
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

#wrapper {
    min-height: 100%;
    position: relative;
}

#content {
    padding-bottom: 210px; /* Height of the footer element */
}

#footer {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.hidden.menu {
    display: none;
}

.masthead.segment {
    min-height: 300px;
    padding: 1em 0em;
}

.masthead .ui.menu .ui.button {
    margin-left: 0.5em;
}

.masthead h1.ui.header {
    margin-top: 3em;
    margin-bottom: 0em;
    font-size: 4em;
    font-weight: normal;
}

.masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
}

.footer.segment {
    padding: 5em 0em;
}

.ui.vertical.segment {
    border: none;
}

.secondary.pointing.menu .toc.item {
    display: none;
}

.ui.secondary.pointing.menu {
    border-bottom: none;
}

@media (min-width: 991px) {
    #sidemenu {
        display: none !important;
    }
}

@media only screen and (max-width: 991px) {
    #navmenu {
        display: none !important;
    }

    .secondary.pointing.menu .item,
    .secondary.pointing.menu .menu {
        display: none;
    }

    .secondary.pointing.menu .toc.item {
        display: block;
    }

    .masthead.segment {
        min-height: 300px;
    }

    .masthead h1.ui.header {
        font-size: 2em;
        margin-top: 1.5em;
    }

    .masthead h2 {
        margin-top: 0.5em;
        font-size: 1.5em;
    }

    .ui.card > .extra, .ui.cards > .card > .extra {
        font-size: medium !important;
    }
}

.ui.card > .extra, .ui.cards > .card > .extra {
    font-size: small;
}

@media only screen and (max-width: 991px) {
    .ui.card > .extra,
    .ui.cards > .card > .extra {
        font-size: small !important;
    }
}

.crop {
    height: 250px;
    overflow: hidden;
}

@media screen and (max-width: 782px) {
    .crop img {
        height: 100%;
    }
}

@media screen and (min-width: 782px) {
    .crop img {
        width: 100%;
    }
}


h2 span {
    color: white;
    font: bold 24px/45px Helvetica, Sans-Serif;
    letter-spacing: -1px;
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    text-align: right;
}

h3 span {
    color: white;
    font: 20px/40px Helvetica, Sans-Serif;
    letter-spacing: -1px;
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    text-align: right;
}


.featherlight .featherlight-content {
    padding: 5px 5px 0 !important;
    border-bottom: 5px solid transparent !important;
}

p {
    text-align: justify;
}
